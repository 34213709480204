var formValidation = require('../components/formValidation');
var formHelpers = require('../checkout/formErrors');
var cleave = require('../components/cleave');
cleave.formatPhone();
const utils = require('../components/utils');
var loginModal = require('../login/loginModal');

/*
    * Functions in this file are used on the My Account - Edit Profile page. In regards to
    fromValidation, this is included from OOTB behavior. This is needed in the
    form.change-password-form on submit function, which is also from OOTB. I chose to
    keep the function here because I noticed pageFooter.isml was including profile.js
    so I didn't want to break exisiting functionality.

    * The first function has to do with marking the Current Password field as 'required'
    only if the new email field, new password or confirm password field is modified.

    * The second function is utilized for switching back to the show profile section
    if the user does not want to make changes to their profile.

    * The third function is utilized for switching from the show profile section
    to the edit profile section

    * The fourth function is utilized for form submission. On a successful response,
    the backend will respond with the show profile section and replace the old html.
    On fail, the spinner will stop, no fail conditions were provided in the ticket.

    * The final function is the OOTB function mentioned in the first point.
*/

/**
 * Returns number of days in the selected month
 * Month index is matched to month number across site
 * (e.g. Jan = 1, Feb = 2, etc...)
 * @param {number} month is month
 * @returns {number} total days in month
 */
function daysInMonth(month) {
    switch (month) {
        case 2:
            return 29;
        case 4: case 6: case 9: case 11:
            return 30;
        default:
            return 31;
    }
}

/**
 * Returns validity of date
 * @param {string} month is month
 * @param {string} day is date
 * @returns {boolean} Returns true if the day is within the month. Returns false if it is an invalid date.
 */
function isValidDate(month, day) {
    var validDay = Number(day);
    var validMonth = Number(month);
    return validDay <= daysInMonth(validMonth);
}

/**
 * Displays error message if date is invalid
 * @param {string} month is the number of the month, not the month name. (Jan = 1, Dec = 12)
 * @param {string} date is day of the month
 * @param {Object} $errorElement is the element/span that contains the error
 * @param {Object} $submitButton is the button to submit the form
*/
function showDateError(month, date, $errorElement, $submitButton) {
    /**
    * Fade in message
    */
    function fadeInError() {
        $errorElement.fadeIn();
        if ($submitButton) $submitButton.attr('disabled', true);
    }
    /**
    * Fade out message
    */
    function fadeOutError() {
        $errorElement.fadeOut();
        $submitButton.attr('disabled', false);
    }

    if (month && date) {
        if (!isValidDate(month, date)) {
            fadeInError();
        } else {
            fadeOutError();
        }
    } else if (!month && !date) {
        fadeOutError();
    } else {
        fadeInError();
    }
}

module.exports = function () {
    $('.edit-profile-form').on('keyup', '.email-field, .password-field, .confirm-password-field', function () {
        var $currentPassField = $('.current-password-field');
        var $currentPassInput = $('input#currentPassword');
        var emailLength = $('.email-field').val().length;
        var passLength = $('.password-field').val().length;
        var confirmpassLength = $('.confirm-password-field').val().length;

        if (emailLength > 0 || passLength > 0 || confirmpassLength > 0) {
            $currentPassField.addClass('required');
            $currentPassInput.attr('required', true);
        } else {
            $currentPassField.removeClass('required');
            $currentPassField.removeClass('is-invalid');
            $currentPassInput.attr('required', false);
            $currentPassInput.removeClass('is-invalid');
            $('.invalid-feedback').empty();
        }
    });

    $('#address-form-birthmonth').on('change', function () {
        var $selectedBirthMonth = $(this);
        var $birthMonthLabel = $('.birthMonth-label');
        if ($selectedBirthMonth.val()) {
            $birthMonthLabel.removeClass('sr-only');
        } else {
            $birthMonthLabel.addClass('sr-only');
        }
    });

    $('#address-form-birthdate').on('change', function () {
        var $selectedBirthDate = $(this);
        var $birthDateLabel = $('.birthDate-label');
        if ($selectedBirthDate.val()) {
            $birthDateLabel.removeClass('sr-only');
        } else {
            $birthDateLabel.addClass('sr-only');
        }
    });

    $('.cancel-edit-profile').on('click', function (e) {
        e.preventDefault();
        $('.show-profile-container').removeClass('d-none');
        $('.edit-profile-container').addClass('d-none');
    });

    $('.my-account-content').on('click', '.edit-profile-btn', function () {
        $('.show-profile-container').addClass('d-none');
        $('.edit-profile-container').removeClass('d-none');
    });

    $('body').on('submit', 'form.edit-profile-form', function (e) {
        e.preventDefault();
        var $form = $(this);
        var url = $form.attr('action');
        var headerHeight = $('.js-site-header').height();
        var suceessEditMessage = $('[name="suceessEditMessage"]');
        var errorEditMessage = $('[name="errorEditMessage"]');
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            data: $form.serialize(),
            success: function (data) {
                // Response can be a template or json. On success it is a template on failure its json
                // when failed, data.success is false and in success its undefined
                if (data.success === false) {
                    if (errorEditMessage.length > 0) {
                        $.alert({
                            content: utils.getTimeAndHours(errorEditMessage.val()),
                            placement: 'banner',
                            theme: 'danger'
                        });
                    }
                    if (data.error) {
                        $('.js-profile-error').addClass('show');
                        $('html, body').animate({
                            scrollTop: $('.js-profile-error').offset().top - 200
                        }, 1000);
                    } else {
                        $('.js-profile-error').removeClass('show');
                        formHelpers.loadFormErrors('.edit-profile-form', data.fields);
                        $('html, body').animate({
                            scrollTop: $('.edit-profile-form .is-invalid').first().offset().top - 100
                        }, 1000);
                    }
                } else {
                    if (suceessEditMessage.length > 0) {
                        $.alert({
                            content: utils.getTimeAndHours(suceessEditMessage.val()),
                            placement: 'banner',
                            theme: 'success'
                        });
                    }
                    $('.js-profile-error').removeClass('show');
                    $('.edit-profile-container').addClass('d-none');
                    $('.show-profile-container').removeClass('d-none').html(data);
                    $('html, body').scrollTop(headerHeight);
                }
                $.spinner().stop();
            },
            error: function (err) {
                if (errorEditMessage.length > 0) {
                    $.alert({
                        content: utils.getTimeAndHours(errorEditMessage.val()),
                        placement: 'banner',
                        theme: 'danger'
                    });
                }
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $.spinner().stop();
            }
        });
        return false;
    });

    $('.js-profile-error .close').on('click', function () {
        $('.js-profile-error').removeClass('show');
    });

    $('form.change-password-form').on('submit', function (e) {
        e.preventDefault();
        var $form = $(this);
        var url = $form.attr('action');
        $.spinner().start();
        $('form.change-password-form').trigger('password:edit', e);
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                $.spinner().stop();
                if (!data.success) {
                    formValidation($form, data);
                } else {
                    location.href = data.redirectUrl;
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                $.spinner().stop();
            }
        });
        return false;
    });

    // Birthday validation
    $('body').on('change', '.js-form-birthdate, .js-form-birthmonth', function () {
        var $selectedElement = $(this);
        var $form = $selectedElement.closest('form');
        var month = $form.find('.js-form-birthmonth').val();
        var date = $form.find('.js-form-birthdate').val();
        var $errorField = $form.find('.birthday-error-field');
        var $submitBtn = $form.find('.js-btn-registration');

        showDateError(month, date, $errorField, $submitBtn);
    }).on('blur', 'form.registration input', function () {
        var $form = $(this.form);
        var month = $form.find('.js-form-birthmonth').val();
        var date = $form.find('.js-form-birthdate').val();

        // birthdate is valid if both parts are empty, OR both parts are set and make a valid date.
        var validBirthdate = (!month && !date) || !!(month && date && isValidDate(month, date));

        $form.find('.js-btn-registration').attr('disabled', !validBirthdate);
    });

    // Newsletter signup submit
    $('.js-newsletterform-submit').on('submit', function (e) {
        e.preventDefault();
        var $form = $(this);
        var $button = $form.find('button');
        if ($($button).hasClass('disabled')) {
            return;
        }
        var url = $form.attr('action');
        var formData = $form.serialize();
        $.spinner().start();

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: formData,
            success: function (data) {
                if (data.success) {
                    $('.newletterform-container').hide();
                    $('.newsletter-signup-banner').hide();
                    $('.signup-thankyou-msg span.user-email').text(data.email);
                    $('.signup-thankyou-msg').removeClass('d-none');
                } else {
                    $('.already-subscribed span.user-email').text(data.msg);
                }

                if ('dtmLayer' in data && 'digitalData' in window && data.dtmLayer.user) {
                    window.digitalData.user = data.dtmLayer.user;
                }
            },
            complete: function () {
                $.spinner().stop();
            }
        });
    });

    /**
     * This click event is used to scroll page if there is any error in the form.
     */
    $('form.edit-profile-form').on('invalidForm', function (event) {
        var $form = $('.edit-profile-form');
        var $invalidField = $form.find('.is-invalid[type !="hidden"]').first();
        if ($invalidField.length > 0) {
            event.stopImmediatePropagation(); // do not call form submit method if there are error in the form.
            $('html, body').animate({
                scrollTop: $invalidField.offset().top - 100
            }, 1000);
        }
    });

    /**
     * This click event is used to check the customer authenticated or not in case of renew application.
     */
    $('.js-renew-button').click(function (f) {
        var url = $(this).data('url');
        if (!url) {
            return;
        }
        f.preventDefault();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            success: function (data) {
                if (data.success && data.redirectUrl) {
                    window.location.href = data.redirectUrl;
                } else {
                    loginModal.launchLoginModal(data.loginUrl);
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
            }
        });
    });
};
